export const SET_SNACKBAR = "SET_SNACKBAR";

const initialState = {
    snackbarOpen: false,
    snackbarType: "success",
    snackbarMessage: ""
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SNACKBAR:
            const { snackbarOpen, snackbarMessage, snackbarType } = action;
            return {
                ...state,
                snackbarOpen,
                snackbarType,
                snackbarMessage
            };
        default:
            return state;
    }
};
export default reducer;

export const setSnackbar = (
    snackbarOpen,
    snackbarType = "success",
    snackbarMessage = ""
) => ({
    type: SET_SNACKBAR,
    snackbarOpen,
    snackbarType,
    snackbarMessage
});

export const success = (
    snackbarMessage
) => ({
    type: SET_SNACKBAR,
    snackbarOpen: true,
    snackbarType: "success",
    snackbarMessage: snackbarMessage
});

export const warning = (
    snackbarMessage
) => ({
    type: SET_SNACKBAR,
    snackbarOpen: true,
    snackbarType: "warning",
    snackbarMessage: snackbarMessage
});

export const info = (
    snackbarMessage
) => ({
    type: SET_SNACKBAR,
    snackbarOpen: true,
    snackbarType: "info",
    snackbarMessage: snackbarMessage
});

export const error = (
    snackbarMessage
) => ({
    type: SET_SNACKBAR,
    snackbarOpen: true,
    snackbarType: "error",
    snackbarMessage: snackbarMessage
});

export const snackActions = {
    success,
    warning,
    info,
    error
}
